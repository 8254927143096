.enrollment-table {
  height: 100%;
  width: 100%;
  margin-bottom: var(--vertical-padding);
  overflow-y: scroll;

  th {
    text-align: right;
  }

  tr {
    min-height: 0px;

    &.course-row:hover {
      background-color: var(--background);
    }
  }

  td {
    height: 100%;
    padding: 12px 0 12px 16px;
    vertical-align: middle;

    .cell-contents {
      width: 100%;
      min-height: 56px;

      .course-icon-container {
        position: relative;
        height: 80px;
        width: 100px;

        .course-icon {
          position: absolute;
          height: 80px;
          width: 80px;
          box-shadow: var(--shadow);
        }
      }

      &.flex-center {
        height: 100%;
        min-height: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.row {
          flex-direction: row;
        }

        &.justify-center {
          justify-content: center;
        }

        &.course-breakdown {
          // display: inline-flex;
          height: 100%;
        }

        .course-breakdown-line {
          width: 100%;
          text-align: right;

          &:not(:first-child) {
            margin-top: 4px;
          }
        }

        .flexer {
          width: 100%;
          min-height: 0px;
          flex-grow: 1;
        }

        .total-divider {
          align-self: flex-end;
          height: 1px;
          width: 96px;
          margin-top: 8px;
          background-color: var(--light-gray);
        }
      }
    }
  }
}
