.teacher-search-result-wrapper {
  .teacher-search-result-content {
    display: flex;
    align-items: center;

    .profile-image {
      height: 48px;
      width: 48px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #ddd;
    }

    .info-container {
      margin-top: -3px;

      .search-result-name {
        font-size: 16px;
        font-weight: 500;
      }

      .search-result-email {
        font-size: 12px;
        color: var(--medium-gray);
      }
    }
  }
}
