.curriculum-table {
  tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
  }

  .cell-contents {
    &.artwork {
      position: relative;
      height: 80px;
      width: 100px;

      .album-artwork-stack-container {
        position: absolute;

        .album-artwork-stacks {
          position: relative;

          .album-artwork {
            position: absolute;
            height: 80px;
            width: 80px;
            box-shadow: var(--shadow);
          }
        }
      }

      .empty-artwork {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        font-size: 14px;
        text-align: center;
      }
    }

    > :not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
