.bundles-tab {
  width: 100%;

  &.bundles-tab-loading {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bundles-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.artwork {
        height: 80px;
        position: relative;

        .product-artwork {
          height: 80px;
          width: 80px;
          position: absolute;
          object-fit: contain;

          &.placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--medium-gray);
            background-color: var(--light-gray);
          }
        }
      }

      .aux {
        color: var(--light-gray);
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  .no-bundles-container {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .no-bundles-message {
      margin-bottom: 32px;
      color: var(--medium-gray);
      font-size: 28px;
      font-weight: 600;
    }
  }
}
