.class-option-wrapper {
  margin-bottom: 12px;

  &.first {
    margin-top: 12px;
  }

  .class-option {
    display: flex;
    align-items: center;

    .course-logo {
      height: 48px;
      width: auto;
      margin-right: 16px;
    }

    .class-period,
    .class-time,
    .class-capacity {
      display: inline-block;
      font-size: 12px;
      color: var(--medium-gray);
    }
    .class-period,
    .class-time {
      margin-right: 12px;
    }

    &.selected .class-capacity,
    &.selected .class-period {
      color: var(--white);
      font-weight: 400;
    }
  }
}
