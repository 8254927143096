.lpm-datepicker {
  --datepicker-height: 40px;

  position: relative;
  width: 100%;
  height: var(--datepicker-height);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  + .lpm-input {
    margin-top: 16px;
  }

  input {
    height: var(--datepicker-height);
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 34px;
    border-radius: 2px;
    outline: none;
    border: none;
    box-shadow: var(--shadow);

    &::placeholder {
      color: var(--medium-gray);
      opacity: 0.5;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-popper {
    margin-top: 4px;
    margin-left: 0px;
  }

  .react-datepicker {
    &,
    * {
      color: var(--secondary-color);
      border-radius: 0px !important;

      &:active,
      &:focus {
        outline: none;
      }
    }

    border-color: #eaeaea;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    .react-datepicker__triangle {
      display: none;
      visibility: hidden;
    }

    .react-datepicker__header {
      background-color: #f7f6f6;
      border-bottom-color: #eaeaea;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      color: #fff;
      background-color: var(--orange);
    }
  }

  .react-datepicker {
    border: none;
    border-radius: 0;
    box-shadow: var(--big-shadow);

    .react-datepicker__header {
      background: var(--super-light-gray);
      border-bottom-color: var(--light-gray);
    }

    .react-datepicker__day {
      border-radius: 0;

      &--selected,
      &--keyboard-selected {
        color: var(--white);
        background: var(--orange);
      }

      &--disabled {
        color: var(--light-gray);
        cursor: default;
      }
    }

    &__time-container {
      .react-datepicker__time-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--dark-gray);
        font-size: 14px;
        text-transform: uppercase;

        &--selected {
          background: var(--orange) !important;
          color: var(--white);
          font-size: 14px;
          text-transform: uppercase;
          &:hover {
            background: var(--orange);
          }
        }
        &:hover {
          background: var(--light-gray);
        }
      }
    }
    .react-datepicker__triangle {
      display: none;
      visibility: hidden;
    }
  }
}
