.donut-chart {
  user-select: none;

  &.no-data {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: var(--medium-gray);
  }
}
