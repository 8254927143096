.sidebar {
  position: relative;
  z-index: 999;
  height: var(--content-height);
  width: var(--sidebar-width);
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  // background-color: #f7f6f6;
  background-color: var(--white);
  border-right: 1px solid #eaeaea;

  .logo-section {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--medium-gray);

    .logo-banner {
      width: 95%;
      padding-top: 2px;
    }
  }

  .links-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .sidebar-link-wrapper {
      display: block;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .sidebar-link {
    padding: 12px;
    display: flex;
    align-items: center;
    color: var(--dark-primary);
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.75px;

    &.back {
      color: var(--white);
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }

    .link-icon {
      height: 20px;
      width: 20px;
      margin-right: 16px;
      stroke: var(--dark-primary);
    }
    &.active .link-icon {
      stroke: var(--white);
    }

    &.back .link-icon {
      stroke: var(--dark-primary);
    }
  }

  .flexer {
    flex-grow: 1;
  }

  .settings-button {
    position: relative;
    height: 32px;
    width: 32px;
    margin-left: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.7;
    }

    &.active {
      background-color: var(--medium-gray);

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 1;
      }

      .icon {
        stroke: var(--white);
      }
    }

    .icon {
      height: 20px;
      width: 20px;
      stroke: var(--medium-gray);
    }

    .settings-menu-content {
      position: absolute;
      bottom: -16px;
      left: calc(100% + 8px);
      // min-height: 100px;
      width: calc(var(--sidebar-width) - 86px);
      display: flex;
      flex-direction: column-reverse;
      background-color: var(--white);
      border-radius: 4px;
      box-shadow: var(--big-shadow);
      cursor: default;

      .settings-menu-header {
        padding: 12px;
        padding-bottom: 4px;
        color: var(--medium-gray);
        font-size: 12px;
        text-transform: uppercase;
      }

      .settings-menu-section {
        margin-bottom: 12px;

        .settings-menu-item {
          padding: 6px 12px;
          font-size: 14px;

          &.action {
            cursor: pointer;
            user-select: none;
          }

          &:hover {
            background-color: var(--super-light-gray);
          }
        }
      }
    }
  }
}
