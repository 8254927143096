.add-file-modal {
  min-height: calc(100vh - 128px);

  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;
  --bottom-buttons-top-margin: 24px;

  position: relative;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    // height: var(--step-content-height);
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .section-container {
      flex: 1;

      &.columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        column-gap: 24px;
      }

      .input-wrapper {
        width: 100%;
        margin-bottom: 36px;

        .input-title {
          margin-bottom: 4px;
          font-weight: 500;
        }
      }

      .file-select-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .file-not-required {
          font-size: 24px;
          color: var(--light-gray);
        }
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: var(--bottom-buttons-height);
    width: 100%;
    margin-top: var(--bottom-buttons-top-margin);
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
