.content.review-screen {
  flex-grow: 1;

  .title {
    margin-bottom: 24px;
    font-size: 32px;

    .go-to-infusionsoft {
      margin-left: 12px;
      color: var(--orange);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .section-container {
    margin-bottom: 24px;

    .section-title {
      margin-bottom: 8px;
      font-weight: 500;
      color: var(--darkGray);

      &.header {
        font-size: 32px;
      }
    }

    .added-materials {
      margin-bottom: 6px;
    }

    .added-material-row {
      color: var(--green);
    }

    .removed-material-row {
      color: var(--red);
    }
  }
}
