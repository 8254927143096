.view-studio-location-on-map-modal {
  position: relative;
  padding: 24px 48px;
  width: 700px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  &.small {
    min-height: 0px;
  }

  .content {
    flex-grow: 1;
  }

  .title {
    margin-bottom: 16px;
    font-size: 32px;
  }

  .map-container {
    width: 100%;
    height: 500px;
    margin: 24px 0px;
  }
}
