.analytics-support-fees-tab {
  width: 100%;

  &.support-fees-loading {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-container {
    width: 100%;
    margin-top: 24px;

    .sort-search-filter-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sort-rows,
      .filter-rows {
        display: flex;
        align-items: center;
        min-width: 350px;

        .sort-rows-label,
        .filter-rows-label {
          margin-right: 16px;
          font-weight: 500;
        }
      }

      .filter-rows {
        min-width: 300px;
      }

      .search-teachers {
        min-width: 360px;
      }
    }

    .support-fees-table {
      margin-top: 32px;
      margin-bottom: var(--vertical-padding);

      tr {
        min-height: 0px;

        &.support-fee-row:hover {
          background-color: var(--background);
          cursor: pointer;
        }
      }

      td {
        height: 100%;
        padding: 12px 0 12px 16px;

        .cell-contents {
          width: 100%;
          min-height: 56px;
          text-align: center;

          &.flex-center {
            display: flex;
            flex-direction: column;
            align-items: center;

            &.row {
              flex-direction: row;
            }

            &.justify-center {
              justify-content: center;
            }

            &.profile {
              align-items: flex-start;
            }

            &.course-breakdown {
              display: inline-flex;
              height: 100%;
            }

            .profile-container {
              display: flex;
              align-items: center;
            }

            .course-breakdown-line {
              width: 100%;
              text-align: right;

              &:not(:first-child) {
                margin-top: 4px;
              }
            }

            .flexer {
              width: 100%;
              min-height: 0px;
              flex-grow: 1;
            }

            .total-divider {
              align-self: flex-end;
              height: 1px;
              width: 96px;
              margin-top: 8px;
              background-color: var(--light-gray);
            }
          }

          .profile-picture {
            height: 56px;
            width: 56px;
            margin-right: 16px;
            border-radius: 50%;
            object-fit: cover;

            &.placeholder {
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--medium-gray);
              background-color: var(--light-gray);
              box-shadow: var(--shadow);

              &.female {
                color: var(--female-pink);
                background-color: var(--female-pink-semi-transparent);
              }

              &.male {
                color: var(--male-blue);
                background-color: var(--male-blue-semi-transparent);
              }
            }
          }
        }
      }
    }
  }
}
