.checkout-button {
  width: 100%;
  max-width: 480px;
  align-self: center;
}

@media screen and (max-width: 568px) {
  .checkout-button {
    width: 100%;
    margin: 36px 0px;
  }
}
