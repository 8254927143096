.analytics-report-tab {
  width: 100%;

  .report-date-select {
    margin-top: 48px;
    margin-left: 24px;

    .report-select-title {
      font-weight: 500;
    }

    .report-selector-column {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      > * {
        margin: 16px;
      }

      .report-selector {
        max-width: 500px;
      }
    }
  }
}
