.promo-code-row {
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;

    .cell-contents {
      &.actions {
        .drag-handle {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
