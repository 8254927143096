.connections-tab {
  height: 100%;
  width: calc(100% + 32px);
  margin: 0px -16px;
  padding: 0px 16px;
  padding-bottom: 32px;
  overflow-y: scroll;

  .relationship-section-header {
    padding-top: 32px;
    margin-bottom: 12px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .relationship-items {
    width: 100%;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 36px;

    .relationship-item {
      padding: 16px;
      padding-bottom: 24px;
      background-color: var(--white);
      border-radius: var(--card-border-radius);
      box-shadow: var(--shadow);

      .relationship-item-name {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
      }

      .relationship-item-detail {
        margin-top: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .relationship-item-detail-title {
          width: 132px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .relationship-item-detail-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .relationship-item-actions {
        margin-top: 8px;
        display: flex;
        justify-content: flex-end;
      }
    }

    .add-child-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.connections-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;

  h2 {
    margin: 0;
  }
}
