.chart-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-column: span 3;

  @for $i from 1 through 12 {
    &.x#{$i} {
      grid-column: span #{$i};
    }
  }

  @for $i from 1 through 4 {
    &.y#{$i} {
      grid-row: span #{$i};
    }
  }

  .chart-title {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;

    .drill-back {
      margin-right: 24px;
      display: flex;
      align-items: center;
      color: var(--primary-color);
      font-size: 16px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }

    .drill-arrow {
      margin: 0px 8px;
    }
  }

  .drill-loader-container {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-container {
    flex: 1;
    min-width: 0px;
    min-height: 0px;

    &.donut {
      padding-bottom: 24px;
    }
  }
}
