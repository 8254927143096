.product-row {
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;

    .cell-contents {
      &.artwork {
        height: 80px;
        position: relative;

        .product-artwork {
          height: 80px;
          width: 80px;
          position: absolute;
          object-fit: contain;

          &.placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--medium-gray);
            background-color: var(--light-gray);
          }
        }
      }

      &.actions {
        .drag-handle {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
