.files-tab {
  height: 100%;
  width: 100%;

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          a {
            color: var(--orange);
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .files-sort-search-row {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .files-sort {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .files-sort-label {
        margin-right: 16px;
        color: var(--medium-gray);
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .files-sort-select {
        width: 340px;
      }
    }

    .files-search {
      min-width: 360px;
    }
  }

  .files-table {
    margin-top: 32px;

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      > :not(:last-child) {
        margin-bottom: 6px;
      }

      .aux {
        font-size: 14px;
        color: var(--medium-gray);
      }
    }
  }
}
