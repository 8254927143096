.add-child-modal {
  position: relative;
  padding: 24px 48px;
  width: 650px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  &.small {
    min-height: 0px;
  }

  .content {
    flex-grow: 1;
  }

  .title {
    margin-bottom: 16px;
    font-size: 32px;
  }

  .input-wrapper {
    max-width: 75%;
    margin-bottom: 24px;

    .input-title {
      margin-bottom: 6px;
      color: var(--medium-gray);
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.75px;
    }

    .child-row {
      max-width: 80%;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .child-number {
        flex-shrink: 0;
        margin-right: 16px;
      }

      .remove-child-icon {
        margin: -36px 0px;
        margin-left: 24px;
        cursor: pointer;
        user-select: none;

        &.disabled {
          pointer-events: none;
        }

        &:active {
          opacity: 0.8;
        }
      }
    }

    .add-child-button-row {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;

      .add-child-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--orange);
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.8;
        }

        span {
          margin-left: 4px;
        }
      }
    }
  }

  .inputs-row-title {
    width: 100%;
    margin-bottom: 6px;
    color: var(--medium-gray);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.75px;
  }

  .inputs-row {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.group {
      margin-bottom: 12px;
    }

    .inputs-row-section {
      width: 100%;
    }

    > *:not(:first-child) {
      margin-left: 16px;
    }

    .lpm-input + .lpm-input {
      margin-top: 0px;
    }
  }

  .inputs-group {
    width: 100%;
    margin-bottom: 32px;

    .lpm-input + .lpm-input {
      margin-top: 12px;
    }
  }

  .message-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .message {
      max-width: 440px;
      font-size: 18px;
      text-align: center;
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .send-request-button {
      height: 40px;
      width: 142px;

      &.button-loading {
        opacity: 0.3;
      }
    }
  }
}
