.collection {
  .collection-title {
    margin-bottom: 20px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.75px;
    border-radius: 6px;
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    > :first-child {
      margin-right: 4px;
    }
  }

  .filters-container {
    margin: 0px 16px 24px;

    .filters-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filters-label {
        flex-shrink: 0;
        margin-right: 24px;
        color: var(--gray);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.75px;
        text-transform: uppercase;
      }
    }
  }

  .collection-charts-container {
    flex: 1;
    min-height: 0px;
    padding-bottom: 64px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 200px;
    grid-gap: 24px;

    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
}
