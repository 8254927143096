.courses-table {
  tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
  }

  .cell-contents {
    &.artwork {
      height: 80px;
      width: 80px;
      position: relative;

      .album-artwork {
        height: 80px;
        width: 80px;
        position: absolute;
        box-shadow: var(--shadow);
      }

      .empty-artwork {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        font-size: 14px;
        text-align: center;
      }
    }

    > :not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
