@import "./fonts/index.scss";

html {
  --dark-primary: #558f37;
  --medium-primary: #b0d896;
  --light-primary: #cbeabc;
  --super-light-primary: #edf5e5;
  --super-light-primary: #f9fbf6;

  --red: #ff5b60;
  --light-red: #ff787c;

  --orange: #ff8935;
  --light-orange: #ff9d45;

  --yellow: #ffb900;
  --light-yellow: #ffce4c;

  --green: #82cd55;
  --light-green: #a3de79;

  --blue: #6cd0fa;
  --light-blue: #b5e8fa;

  --purple: #8e8df0;
  --light-purple: #91a7f7;

  --black: #000;
  --white: #fff;

  --dark-gray: #3b3b3b;
  --medium-gray: #959499;
  --light-gray: #dddddd;
  --super-light-gray: #f7f6f6;

  --background: var(--super-light-primary);

  --shadow-color: rgba(0, 0, 0, 0.05);
  --shadow: 0px 0.5px 2px 2px var(--shadow-color);
  --big-shadow: 0px 1px 4px 4px var(--shadow-color);

  --card-border-radius: 4px;

  --Sound-Beginnings: #ff8935;
  --Lets-Play-Music: #82cd55;
  --Presto: #8e8df0;
  --Online: #6cd0fa;

  --chart-1: var(--blue);
  --chart-2: var(--green);
  --chart-3: var(--orange);
  --chart-4: var(--purple);
  --chart-5: var(--yellow);
  --chart-6: var(--light-red);
  --chart-7: var(--light-purple);
  --chart-hover-overlay: rgba(225, 223, 236, 0.25);
  --chart-gray: #666666;

  body {
    color: var(--dark-gray);
  }

  * {
    font-family: "Lexend Deca", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    input::-webkit-input-placeholder,
    input:-ms-input-placeholder,
    input:-moz-placeholder,
    input::-moz-placeholder,
    input::placeholder {
      font-family: "Lexend Deca", sans-serif;
    }
  }
}
