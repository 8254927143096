.edit-registration-details-modal {
  position: relative;
  padding: 24px 48px;
  width: 540px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  &.small {
    min-height: 0px;
  }

  .content {
    flex-grow: 1;

    .section-container {
      margin-bottom: 24px;

      &.double {
        display: flex;
        flex-direction: row;

        > * {
          flex: 1;

          .checkbox-wrapper {
            height: 40px;
            display: flex;
            align-items: center;
          }

          &.small {
            flex: 0.5;
          }

          &:first-child {
            margin-right: 16px;
          }

          &:last-child {
            margin-left: 16px;
          }

          &:not(:first-child):not(:last-child) {
            margin: 0px 16px;
          }
        }
      }

      .section-title {
        margin-bottom: 8px;
        font-weight: 500;
        color: var(--darkGray);

        &.header {
          font-size: 32px;
        }
      }
    }
  }

  .title {
    margin-bottom: 24px;
    font-size: 32px;
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .send-request-button {
      height: 40px;
      width: 142px;

      &.button-loading {
        opacity: 0.3;
      }
    }
  }
}
