.account-tab {
  height: 100%;
  width: 100%;
  display: flex;

  &.account-tab-loading {
    height: 400px;
    justify-content: center;
    align-items: center;
  }

  .info-col {
    margin-top: 32px;
    flex: 1;

    &:not(:last-child) {
      margin-right: 32px;
    }

    .info-section {
      margin-bottom: 32px;

      .info-section-header {
        margin-bottom: 12px;
        color: var(--medium-gray);
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .info-content {
        padding: 16px;
        background-color: var(--white);
        border-radius: var(--card-border-radius);
        box-shadow: var(--shadow);
      }

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-bottom: 0px;
        }

        &.cont {
          margin-top: -16px;
        }

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);

          &.with-action {
            justify-content: space-between;
          }

          .action {
            color: var(--orange);
            font-weight: 600;
            user-select: none;
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }

            &:not(:first-child) {
              margin-left: 16px;
            }
          }
        }

        .info-action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
