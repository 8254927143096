.lpm-input-icon.secure {
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }
}
