.table-wrapper {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  padding: 12px;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: var(--shadow);
}

.table-zero-state {
  width: 100%;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .table-zero-state-message {
    width: 75%;
    margin-bottom: 24px;
    text-align: center;
    font-size: 24px;
    color: var(--medium-gray);
    opacity: 0.5;
  }
}

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-variant-numeric: tabular-nums;

  th {
    padding: 10px 0 10px 16px;
    color: var(--dark-primary);
    background-color: var(--super-light-gray);
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    vertical-align: bottom;
    border-bottom: 3px solid var(--light-gray);

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }
  }

  td {
    border-bottom: 1px solid var(--light-gray);
    padding: 30px 0 30px 16px;
    font-weight: 600;
    vertical-align: top;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    .action {
      color: var(--orange);
      font-weight: 700;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      + .action {
        margin-top: 4px;
      }
    }
  }

  tr:last-child td {
    border-bottom: none;
  }
}
