.students-table .student-item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &.flex-center {
    display: flex;
    align-items: center;
  }

  .profile-picture {
    height: 56px;
    width: 56px;
    margin-right: 16px;
    border-radius: 50%;
    object-fit: cover;

    &.placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--medium-gray);
      background-color: var(--light-gray);
      box-shadow: var(--shadow);

      &.female {
        color: var(--female-pink);
        background-color: var(--female-pink-semi-transparent);
      }

      &.male {
        color: var(--male-blue);
        background-color: var(--male-blue-semi-transparent);
      }
    }
  }

  .aux {
    margin-top: 4px;
    color: var(--medium-gray);
    font-size: 14px;
  }
}
