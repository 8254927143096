.search-result-wrapper {
  .search-result-content {
    padding: 4px 0px;
    display: flex;

    .profile-image {
      height: 64px;
      width: 64px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #ddd;
    }

    .info-container {
      .search-result-name {
        font-size: 16px;
        font-weight: 500;
      }

      .search-result-email {
        color: var(--medium-gray);
        font-size: 12px;
      }

      .search-result-id {
        margin-top: 1px;
        color: var(--medium-gray);
        font-size: 12px;
      }

      .person-type-chips-container {
        margin-top: 6px;
        display: flex;

        .chip {
          height: 20px;
          margin-right: 6px;
          padding: 6px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white);
          border-radius: 10px;
          font-size: 14px;
          line-height: 16px;

          &.parent {
            background-color: var(--purple);
          }

          &.student {
            background-color: var(--orange);
          }

          &.teacher {
            background-color: var(--blue);
          }
        }
      }
    }
  }

  .match-explanation {
    margin-top: 8px;
    color: var(--medium-gray);
    font-size: 12px;
  }
}
