.products-tab {
  width: 100%;

  &.products-tab-loading {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .products-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);
  }

  .no-products-container {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .no-products-message {
      margin-bottom: 32px;
      color: var(--medium-gray);
      font-size: 28px;
      font-weight: 600;
    }
  }
}
