.bar-chart {
  user-select: none;

  &.no-data {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: var(--medium-gray);
  }

  .recharts-legend-wrapper {
    .recharts-default-legend {
      .recharts-legend-item {
        cursor: pointer;
      }

      &:hover {
        .recharts-legend-item {
          opacity: 0.3;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
