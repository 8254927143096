.edit-promo-codes-modal {
  width: 1024px;

  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;
  --bottom-buttons-top-margin: 24px;

  position: relative;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .content {
    flex: 1;
    width: 100%;
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .main-content-wrapper {
      width: 100%;
      display: flex;

      .col {
        flex: 1;

        .section-container {
          flex: 1;
          margin: 16px;

          .section-title {
            margin-bottom: 8px;
            font-weight: 500;
            color: var(--darkGray);
          }

          .information-text {
            margin: 4px 8px;
            font-size: 12px;
            font-weight: 500;
            color: var(--medium-gray);
          }
        }

        &.right .section-container:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: var(--bottom-buttons-height);
    width: 100%;
    margin-top: var(--bottom-buttons-top-margin);
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }

  .input-percentage {
    display: grid;
    grid-template-columns: auto 30px;
    border: 1px solid gray;
  }

  .input-percentage input,
  .input-percentage input:focus {
    border: 0px;
    outline: none;
  }

  .input-percentage .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    cursor: pointer;
  }

  .input-percentage .arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 5px;
    margin-left: 0px;
  }

  .input-percentage .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 11px;
    margin-left: 0px;
  }
}
