.wrapper {
  position: relative;
  height: 680px;
  width: 540px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .content {
    flex: 1;
    width: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .inputTitle {
      margin-top: 24px;
      margin-bottom: 8px;
      font-weight: 500;

      &.first {
        margin-top: 0px;
      }
    }

    .inputSubTitle {
      font-weight: 400;
      color: var(--medium-gray);
    }

    .outOfEditRange {
      margin: auto 32px;
      // text-align: center;
      color: var(--medium-gray);
      font-weight: 500;
      line-height: 22px;

      a {
        color: var(--orange);
      }
    }

    .courseDescription {
      font-size: 14px;
      color: #666;
      margin-bottom: 12px;
      margin-top: -4px;
    }

    .DatePicker {
      margin-bottom: 24px;
    }
  }

  .bottomButtons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftButtons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modalButton {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
