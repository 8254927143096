.edit-materials-modal {
  position: relative;
  padding: 24px 48px;
  width: 580px;
  height: calc(100vh - 2 * 64px);
  min-height: 680px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    button.button-loading {
      opacity: 0.3;
    }
  }
}
