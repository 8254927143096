.people-search {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  overflow-y: scroll;

  .title-row {
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }

    .search-input-wrapper {
      width: 500px;
      min-width: 300px;
      max-width: 700px;
    }
  }

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .recently-viewed {
    flex: 1;
    display: flex;
    flex-direction: column;

    .recently-viewed-header {
      padding-top: 24px;
      margin-bottom: 24px;
      color: var(--medium-gray);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .recently-viewed-zero-state-wrapper {
      width: 100%;
      margin-bottom: 64px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .recently-viewed-zero-state-text {
        font-size: 24px;
        color: var(--medium-gray);
      }
    }

    .recently-viewed-items {
      width: 100%;
      margin-bottom: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-gap: 36px;

      .recently-viewed-item-wrapper {
        padding: 16px;
        background-color: var(--white);
        border-radius: var(--card-border-radius);
        box-shadow: var(--shadow);
        cursor: pointer;

        .recently-viewed-item-content {
          display: flex;

          .profile-image {
            height: 64px;
            width: 64px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #ddd;
          }

          .info-container {
            .recently-viewed-item-name {
              font-size: 16px;
              font-weight: 500;
            }

            .recently-viewed-item-email {
              color: var(--medium-gray);
              font-size: 12px;
            }

            .recently-viewed-item-id {
              margin-top: 1px;
              color: var(--medium-gray);
              font-size: 12px;
            }

            .person-type-chips-container {
              margin-top: 6px;
              display: flex;

              .chip {
                height: 20px;
                margin-right: 6px;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white);
                border-radius: 10px;
                font-size: 14px;
                line-height: 16px;

                &.parent {
                  background-color: var(--purple);
                }

                &.student {
                  background-color: var(--orange);
                }

                &.teacher {
                  background-color: var(--blue);
                }
              }
            }
          }
        }
      }
    }
  }
}
