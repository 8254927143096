.heat-map {
  display: grid;

  .cell {
    display: flex;

    &.cell-header {
      border-bottom: 2px solid var(--medium-gray);
      font-size: 13px;
    }

    &.row-start {
      border-right: 2px solid var(--medium-gray);
      font-size: 13px;
    }

    &.column-header {
      justify-content: center;
      align-items: center;
    }

    &.row-label {
      padding-right: 8px;
      justify-content: flex-end;
      align-items: center;
    }

    &.value {
      justify-content: center;
      align-items: center;

      &.bucket-0 {
        background-color: rgba(212, 57, 78, 0.075);
      }
      &.bucket-1 {
        background-color: rgba(212, 57, 78, 0.15);
      }
      &.bucket-2 {
        background-color: rgba(212, 57, 78, 0.225);
      }
      &.bucket-3 {
        background-color: rgba(212, 57, 78, 0.3);
      }
      &.bucket-4 {
        background-color: rgba(212, 57, 78, 0.375);
      }
      &.bucket-5 {
        background-color: rgba(212, 57, 78, 0.45);
      }
      &.bucket-6 {
        background-color: rgba(212, 57, 78, 0.525);
      }
      &.bucket-7 {
        background-color: rgba(212, 57, 78, 0.6);
      }
      &.bucket-8 {
        background-color: rgba(212, 57, 78, 0.675);
      }
      &.bucket-9 {
        background-color: rgba(212, 57, 78, 0.75);
      }
    }
  }
}
