.add-enrollment {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.add-enrollment-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .content {
    display: flex;

    .col {
      flex: 1;

      &:not(:first-child) {
        margin-left: 64px;
      }

      .section {
        max-width: 600px;

        .section-header {
          height: 55px;
          margin-bottom: 12px;
          padding-top: 32px;
          color: var(--medium-gray);
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
        }

        .shipment-date {
          margin: 8px;
        }

        .list-type-title {
          padding: 12px 0px;
          color: var(--medium-gray);
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .materials-zero-state {
          width: 100%;
          color: var(--medium-gray);
          text-align: center;
          opacity: 0.5;
        }
      }
    }
  }
}
