.update-details-modal {
  --modal-height: 90vh;
  --modal-header-height: 110px;
  --modal-bottom-buttons-height: 110px;
  --modal-content-height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-bottom-buttons-height));
  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;
  --bottom-buttons-top-margin: 24px;

  position: relative;
  min-height: 100%;
  width: 100%;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .section-container {
      &.columns {
        display: flex;
        justify-content: space-between;

        .col {
          flex: 1;

          &:not(:first-child) {
            margin-left: 64px;
          }
        }
      }

      .section-container {
        margin-bottom: 36px;

        .section-title {
          margin-bottom: 8px;
          font-weight: 500;
          color: var(--darkGray);
        }

        .learning-purpose-row {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          :first-child {
            margin-right: 24px;
          }
        }

        .add-learning-purpose {
          display: inline-flex;
          align-items: center;
          color: var(--green);
          stroke: var(--green);
          cursor: pointer;
          user-select: none;

          :first-child {
            margin-right: 8px;
          }
        }
      }
    }

    .long-running-message {
      margin-top: 16px;
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: var(--bottom-buttons-height);
    width: 100%;
    margin-top: var(--bottom-buttons-top-margin);
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
