.mobile {
  --top-padding: 32px;
  --title-height: 45px;
  --title-bottom-margin: 16px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--top-padding) 44px 0px;
  background-color: var(--background);
  overflow-y: hidden;

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
      display: flex;
      align-items: center;
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs-container {
    --tab-bar-height: 52px;

    height: calc(100% - var(--title-height) - var(--title-bottom-margin));

    .tab-bar {
      height: var(--tab-bar-height);
      display: grid;
      grid-template-rows: 1fr;

      .tab {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        border-bottom: 3px solid var(--light-gray);
        transition: border-bottom 400ms;
        cursor: pointer;

        &.active {
          color: var(--gray);
          border-bottom: 3px solid var(--dark-primary);
        }
      }
    }

    .active-tab-section {
      height: calc(100% - var(--tab-bar-height));
      overflow-y: scroll;
    }
  }
}
