.confirm-delete-course-product-modal {
  --modal-height: 280px;
  --modal-header-height: 110px;
  --modal-bottom-buttons-height: 110px;

  --modal-content-height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-bottom-buttons-height));

  height: var(--modal-height);
  width: 650px;

  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;
  --bottom-buttons-top-margin: 24px;

  position: relative;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    // height: var(--step-content-height);
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 32px;
      font-size: 32px;
      font-weight: 500;
    }

    .section-container {
      flex: 1;

      &.columns {
        display: flex;
        justify-content: space-between;

        .col {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .file-upload-preview {
            height: 400px;
            width: 400px;
            box-shadow: var(--shadow);
          }

          .file-upload-zone {
            height: 400px;
            width: 400px;
            padding: 16px;
            background-color: var(--light-gray);
            box-shadow: var(--shadow);
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.9;
            }

            .file-upload-zone-inner {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 4px solid var(--medium-gray);

              .file-upload-zone-label {
                padding: 36px;
                color: var(--medium-gray);
                font-size: 28px;
                text-align: center;
              }
            }
          }

          .hidden-file-input {
            display: none;
            visibility: hidden;
          }
        }
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: var(--bottom-buttons-height);
    width: 100%;
    margin-top: var(--bottom-buttons-top-margin);
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
