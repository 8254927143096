.students-table .roster-row {
  .cell-contents {
    &.flex-center {
      display: flex;
      align-items: center;
    }

    .profile-picture {
      height: 56px;
      width: 56px;
      margin-right: 16px;
      border-radius: 50%;
      object-fit: cover;

      &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        background-color: var(--light-gray);
        box-shadow: var(--shadow);

        &.female {
          color: var(--female-pink);
          background-color: var(--female-pink-semi-transparent);
        }

        &.male {
          color: var(--male-blue);
          background-color: var(--male-blue-semi-transparent);
        }
      }
    }

    .aux {
      margin-top: 4px;
      color: var(--medium-gray);
      font-size: 14px;
    }
  }

  .product-container {
    // width: 100%;
    display: flex;
    align-items: center;

    .fulfilled-checkmark {
      color: var(--dark-primary);
      margin-right: 6px;
      stroke-width: 4;
    }

    .product-name {
      margin-right: 4px;
    }

    .product-quantity {
      margin: 0px 4px;
    }
  }
}
