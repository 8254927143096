.Toastify {
  .Toastify__toast-container {
    width: 348px;

    .positive {
      &::before {
        background-color: var(--green);
      }
    }
  }

  > * > * {
    position: relative;
    color: var(--dark-gray);
    border-radius: 8px;
    padding: 8px 8px 8px 24px;
    font-family: "Lexend Deca", sans-serif;
    box-shadow: var(--shadow);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: var(--red);
      // animation: 1s infinite alternate breathe ease-out;
    }
  }

  .Toastify__progress-bar--default {
    background: none;
  }
}

@keyframes breathe {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
