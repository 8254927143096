.product-item-wrapper {
  min-height: 108px;
  padding: 12px 20px 12px 12px;
  background-color: var(--white);
  box-shadow: var(--shadow);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .product-item-content {
    height: 100%;
    display: flex;
    align-items: center;

    .preview-image {
      height: 84px;
      width: 84px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;

      &.fallback {
        background-color: #ddd;
      }
    }

    .info-container {
      flex: 1;
      align-self: stretch;
      display: flex;
      flex-direction: column;

      .product-item-title {
        font-size: 16px;
        font-weight: 500;
      }

      .product-item-info {
        font-size: 14px;
        color: var(--medium-gray);
      }

      .product-item-price {
        align-self: flex-end;
      }
    }

    .quantity-container {
      margin-left: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 600;
      color: var(--dark-primary);

      > :not(:last-child) {
        margin-right: 12px;
      }

      .quantity-icon {
        cursor: pointer;

        &:active {
          opacity: 0.8;
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
}
