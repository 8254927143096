.lpm-button {
  height: 40px;
  min-width: 130px;
  padding: 0px 20px;
  border-radius: 2px;
  outline: none;
  border: 2px solid var(--dark-primary);
  color: var(--dark-primary);
  background-color: transparent;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  &:active {
    opacity: 0.9;
  }

  &.light {
    color: var(--white);
    border-color: var(--white);

    &:active {
      opacity: 0.6;
    }
  }

  &.primary {
    padding: 0 12px;
    color: var(--white);
    background-color: var(--dark-primary);
  }

  &.ancillary {
    min-width: auto;
    color: var(--medium-gray);
    border-color: transparent;
    font-weight: 700;

    &:active {
      opacity: 0.6;
    }
  }

  &.negative {
    padding: 0 12px;
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
    border-radius: 10px;
    font-weight: 600;
  }

  &.cta {
    padding: 0 12px;
    color: var(--white);
    background-color: var(--dark-primary);
    border-color: var(--dark-primary);
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
}
