.lpm-checkbox-group {
  position: relative;
  width: 100%;

  + .lpm-input {
    margin-top: 16px;
  }
}

.lpm-input + .lpm-checkbox-group {
  margin-top: 32px;
}
