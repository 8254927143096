.add-enrollment {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.add-enrollment-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .add-enrollment-content-container {
    display: flex;

    .header {
      height: var(--header-height);
      margin-bottom: var(--header-bottom-margin);
      padding-top: 32px;
      color: var(--medium-gray);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .left-col {
      flex: 1;
      width: 640px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .right-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .blocked {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
