.playlists-tab {
  height: 100%;
  min-height: 350px;
  width: 100%;

  .playlists-table {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.artwork {
        height: 80px;
        position: relative;

        .album-artwork {
          height: 80px;
          width: 80px;
          position: absolute;
          box-shadow: var(--shadow);
        }
      }

      &.actions {
        .drag-handle {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
