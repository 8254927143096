.add-relationship-modal {
    height: max-content;
    width: 650px;
    position: relative;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);

    .content {
        flex: 1;
        width: 100%;
        padding: 0px 30px;
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 32px;
            font-size: 32px;
            font-weight: 500;
        }

        .section {
            margin-bottom: 36px;

            .section-header {
                margin-bottom: 8px;
                color: var(--medium-gray);
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }

    .bottom-buttons {
        flex-shrink: 0;
        height: 60px;
        width: 100%;
        margin-top: 24px;
        padding: 0px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left-buttons {
            display: flex;

            > :not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

.relationship-summary {
    .summary-content {
        padding: 20px;
        background-color: var(--super-light-gray);
        border-radius: 4px;

        .summary-users {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            .user {
                display: flex;
                align-items: center;
                gap: 12px;

                .profile-image {
                    min-width: 40px;
                    min-height: 40px;
                    aspect-ratio: 1/1;
                    max-width: 40px;
                    max-height: 40px;
                    border-radius: 50%;
                    background-color: var(--white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .user-info {
                    .name {
                        font-weight: 500;
                        color: var(--dark-primary);
                    }

                    .description {
                        font-size: 12px;
                        color: var(--medium-gray);
                    }
                }
            }

            .relationship-arrow {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;

                .arrow-label {
                    font-size: 14px;
                    color: var(--medium-gray);
                    text-align: center;
                }

                .arrow {
                    font-size: 24px;
                    color: var(--main);
                    font-weight: bold;
                }
            }
        }
    }
}

.no-relationships-available {
    padding: 16px;
    background-color: var(--super-light-gray);
    color: var(--medium-gray);
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
}