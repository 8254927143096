.analytics {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.analytics-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .tabs-container {
    margin: 0px -16px;
    margin-top: 24px;
    padding: 0px 16px;
    padding-bottom: var(--vertical-padding);

    .tab-bar {
      height: 52px;
      display: grid;
      grid-template-rows: 1fr;

      .tab {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        border-bottom: 3px solid var(--light-gray);
        transition: border-bottom 400ms;
        cursor: pointer;

        &.active {
          color: var(--gray);
          border-bottom: 3px solid var(--dark-primary);
        }
      }
    }

    .active-tab-section {
      margin: 0px -16px;
      padding: 0px 16px;
    }
  }
}
