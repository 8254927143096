.verify-content {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .verify-square {
    // height: 440px;
    width: 382px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    .verify-logo {
      width: 70%;
    }

    .error-container {
      margin: 8px;

      .error-text {
        font-size: 12px;
        color: var(--red);
      }
    }

    .verify-inputs {
      width: 322px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .log-in-button {
      width: 322px;
      margin-top: 32px;
    }

    .lpm-input-error + .verify-button {
      margin-top: 8px;
    }

    .forgot-password {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 800;
      line-height: 22px;
      opacity: 0.3;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.2;
      }
    }

    .no-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 64px;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 800;
      line-height: 22px;
      user-select: none;

      > span {
        color: initial;
        opacity: 0.3;
      }

      .sign-up-button {
        width: 322px;
        margin-top: 12px;
      }
    }
  }
}
