.enrollment {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.enrollment-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 220px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          a {
            color: var(--orange);
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .enrollments-table {
    margin-top: 32px;

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.date {
        color: var(--medium-gray);

        :not(:first-child) {
          margin-left: 4px;
          margin-top: -6px;
        }
      }

      &.artwork {
        position: relative;
        height: 80px;
        width: 100px;

        .album-artwork {
          position: absolute;
          height: 80px;
          width: 80px;
          box-shadow: var(--shadow);
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }

      .student-line {
        margin-top: -6px;
      }

      .aux {
        font-size: 14px;
        color: var(--medium-gray);

        &.class-details {
          margin-top: -4px;
          margin-bottom: 2px;

          &.first {
            margin-top: 0px;
          }
        }

        &.registered-by {
          margin-top: -6px;
        }
      }
    }
  }
}
