.banners-tab {
  width: 100%;
  margin-bottom: 32px;

  &.banners-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 220px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          a {
            color: var(--orange);
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .banners-table {
    margin-top: 32px;

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .banner-row {
      background-color: var(--white);
    }

    .cell-contents {
      &.actions {
        width: 180px;

        > * {
          width: 100%;
        }

        .drag-handle {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      .media {
        width: 140px;
        margin-right: 16px;
        object-fit: cover;

        &.placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--medium-gray);
          background-color: var(--light-gray);
          box-shadow: var(--shadow);

          &.female {
            color: var(--female-pink);
            background-color: var(--female-pink-semi-transparent);
          }

          &.male {
            color: var(--male-blue);
            background-color: var(--male-blue-semi-transparent);
          }
        }
      }

      .url {
        color: var(--orange);
      }
    }
  }
}
