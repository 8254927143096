.relationship-info-modal {
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 24px;
    .content {
      
      .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
        color: var(--dark-primary);
      }
  
      .info-text {
        background-color: var(--super-light-gray);
        padding: 16px;
        border-radius: 4px;
        
        p {
          margin: 0;
          line-height: 1.5;
          color: var(--medium-gray);
          
          &:not(:last-child) {
            margin-bottom: 12px;
          }
  
          strong {
            color: var(--dark-primary);
          }
        }
      }
    }
  
    .bottom-buttons {
      padding: 16px 24px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid var(--border-gray);
    }
  }