.student-line-wrapper {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .student-name-label {
    margin-bottom: 8px;
    font-weight: 500;
  }
}

.bundle-item-wrapper {
  min-height: 108px;
  margin: 12px;
  padding: 12px 20px 12px 12px;
  background-color: var(--white);
  border: 4px solid var(--white);
  box-shadow: var(--shadow);
  cursor: pointer;

  &.selected {
    border: 4px solid var(--dark-primary);
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:active {
    opacity: 0.8;
  }

  .bundle-item-content {
    height: 100%;
    display: flex;

    .preview-image {
      height: 84px;
      width: 84px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info-container {
      flex: 1;
      display: flex;
      flex-direction: column;

      .bundle-item-title {
        font-size: 16px;
        font-weight: 500;
      }

      .bundle-item-info {
        font-size: 14px;
        color: var(--medium-gray);
      }

      .bundle-item-price {
        align-self: flex-end;
      }
    }
  }
}
