.person-overview {
  --vertical-padding: 32px;
  --title-height: 45px;
  --title-bottom-margin: 16px;
  --chips-height: 28px;
  --chips-bottom-margin: 16px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--vertical-padding) 44px 0px;
  background-color: var(--background);
  overflow-y: scroll;

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chips-container {
    height: var(--chips-height);
    width: 100%;
    margin-bottom: var(--chips-bottom-margin);
    display: flex;

    .chip {
      height: 28px;
      margin-right: 12px;
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white);
      border-radius: 14px;
      font-size: 16px;
      line-height: 16px;

      &.parent {
        background-color: var(--purple);
      }

      &.student {
        background-color: var(--orange);
      }

      &.teacher {
        background-color: var(--blue);
      }
    }
  }

  .tabs-container {
    margin: 0px -16px;
    padding: 0px 16px;
    padding-bottom: var(--vertical-padding);

    .tab-bar {
      height: 52px;
      display: grid;
      grid-template-rows: 1fr;

      .tab {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        border-bottom: 3px solid var(--light-gray);
        transition: border-bottom 400ms;
        cursor: pointer;

        &.active {
          color: var(--gray);
          border-bottom: 3px solid var(--dark-primary);
        }
      }
    }

    .active-tab-section {
      margin: 0px -16px;
      padding: 0px 16px;
    }
  }
}
