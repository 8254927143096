.class-overview {
  height: var(--content-height);
  width: var(--content-width);
  display: flex;
  flex-direction: column;
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  .title-row {
    height: 35px;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 28px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .header {
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.cont {
          margin-top: -16px;
        }

        .info-title {
          width: 206px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);

          .seats-available-details {
            margin-left: 12px;
            font-size: 14px;
            color: var(--medium-gray);
          }
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .loading-container {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
