.sign-up-content {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sign-up-square {
    // height: 440px;
    width: 640px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    .columns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sign-up-inputs {
      width: 580px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .inputs-row-title {
        width: 100%;
        margin-bottom: 6px;
        color: var(--medium-gray);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.75px;
      }

      .inputs-row-subtitle {
        width: 100%;
        margin-top: -4px;
        margin-bottom: 12px;
        color: var(--medium-gray);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.75px;
      }

      .inputs-row {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.group {
          margin-bottom: 12px;
        }

        .inputs-row-section {
          width: 100%;
        }

        > *:not(:first-child) {
          margin-left: 16px;
        }

        .lpm-input + .lpm-input {
          margin-top: 0px;
        }
      }

      .inputs-group {
        width: 100%;
        margin-bottom: 32px;

        .lpm-input + .lpm-input {
          margin-top: 12px;
        }

        .error-container {
          margin: 8px;

          .error-text {
            font-size: 12px;
            color: var(--red);
          }
        }
      }
    }

    .log-in-button {
      width: 580px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .lpm-input-error + .sign-up-button {
      margin-top: 8px;
    }

    .forgot-password {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 800;
      line-height: 22px;
      opacity: 0.3;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.2;
      }
    }

    .no-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 64px;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 800;
      line-height: 22px;
      user-select: none;

      > span {
        color: initial;
        opacity: 0.3;
        width: 100%;
      }

      .sign-up-button {
        width: 380px;
        margin-top: 12px;
      }
    }
  }
}
