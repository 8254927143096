.content.edit-screen {
  flex-grow: 1;

  .title {
    margin-bottom: 24px;
    font-size: 32px;

    .go-to-infusionsoft {
      margin-left: 12px;
      color: var(--orange);
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      user-select: none;

      &:active {
        opacity: 0.8;
      }
    }
  }

  .section-container {
    margin-bottom: 24px;

    .section-title {
      margin-bottom: 8px;
      font-weight: 500;
      color: var(--darkGray);

      &.header {
        font-size: 32px;
      }
    }

    .action-button {
      width: 24px;
      cursor: pointer;
    }

    .material-row {
      margin: 8px 0px;
      display: flex;
      align-items: center;

      .material-name-col {
        flex: 1;
        margin-right: 8px;

        .material-extra-info {
          color: var(--medium-gray);
          font-size: 12px;
        }
      }

      .material-quantity {
        margin: 0px 12px 0px 8px;
        width: 40px;

        .quantity-input {
          text-align: right;
        }
      }
    }

    .add-material-row {
      margin-top: 16px;
      display: flex;

      .add-material {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--orange);
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.8;
        }

        span {
          margin-left: 4px;
        }
      }
    }

    .section-info {
      margin: 32px 0px;

      &.center {
        display: flex;
        justify-content: center;
      }

      .max-message {
        max-width: 75%;
        color: var(--red);
        text-align: center;
      }
    }
  }
}
