.update-studio-location-modal {
  position: relative;
  padding: 24px 48px;
  width: 700px;
  height: 660px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  &.small {
    min-height: 0px;
  }

  .content {
    flex-grow: 1;
  }

  .title {
    margin-bottom: 16px;
    font-size: 32px;
  }

  .message {
    max-width: 580px;
    margin-bottom: 24px;
    font-size: 18px;
    color: var(--medium-gray);
  }

  .section-container {
    margin-bottom: 24px;

    &.double {
      display: flex;
      flex-direction: row;

      > * {
        flex: 1;

        .checkbox-wrapper {
          height: 40px;
          display: flex;
          align-items: center;
        }

        &.small {
          flex: 0.5;
        }

        &:first-child {
          margin-right: 16px;
        }

        &:last-child {
          margin-left: 16px;
        }

        &:not(:first-child):not(:last-child) {
          margin: 0px 16px;
        }
      }
    }

    .section-title {
      margin-bottom: 8px;
      font-weight: 500;
      color: var(--darkGray);

      &.header {
        font-size: 32px;
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .send-request-button {
      height: 40px;
      width: 142px;

      &.button-loading {
        opacity: 0.3;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .enter-registration-code-modal {
    padding: 20px;
    width: calc(100vw - 40px);
    height: auto;

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .input-wrapper {
      max-width: 100%;
      margin-bottom: 0px;
    }

    .inputs-row-title {
      font-size: 12px;
    }

    .message-wrapper {
      .message {
        font-size: 14px;
      }
    }

    .bottom-buttons {
      padding: 0px;
    }
  }
}
