.course-details {
  --vertical-padding: 32px;
  --title-height: 35px;
  --title-bottom-margin: 16px;
  --upper-height: 175px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--vertical-padding) 44px 0px;
  background-color: var(--background);
  overflow-y: scroll;

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 28px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: var(--header-height);
    margin-bottom: var(--header-bottom-margin);
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;
    margin-bottom: 32px;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .tabs-container {
    padding-bottom: var(--vertical-padding);

    .tab-bar {
      height: 52px;
      display: grid;
      grid-template-rows: 1fr;

      .tab {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        border-bottom: 3px solid var(--light-gray);
        transition: border-bottom 400ms;
        cursor: pointer;

        &.active {
          color: var(--gray);
          border-bottom: 3px solid var(--dark-primary);
        }
      }
    }

    .active-tab-section {
    }
  }
}
