.classes-tab {
  height: 100%;
  width: 100%;

  .classes-filter-row {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .classes-filter-label {
      margin-right: 16px;
      color: var(--medium-gray);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .classes-filter-select {
      width: 340px;
    }
  }

  .classes-table {
    margin-top: 32px;

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.artwork {
        position: relative;
        height: 80px;
        width: 100px;

        .album-artwork {
          position: absolute;
          height: 80px;
          width: 80px;
          box-shadow: var(--shadow);
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }

      .aux {
        font-size: 14px;
        color: var(--medium-gray);
      }

      .seats-available-details {
        font-size: 14px;
        color: var(--medium-gray);
      }
    }
  }
}
