.search-result-wrapper {
    .search-result-content {
        padding: 4px 0px;
        display: flex;

        .profile-image {
            height: 64px;
            width: 64px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #ddd;
        }

        .info-container {
            .search-result-name {
                font-size: 16px;
                font-weight: 500;
            }

            .search-result-email {
                color: var(--medium-gray);
                font-size: 12px;
            }

            .search-result-id {
                margin-top: 1px;
                color: var(--medium-gray);
                font-size: 12px;
            }


        }
    }

    .match-explanation {
        margin-top: 8px;
        color: var(--medium-gray);
        font-size: 12px;
    }

    .person-type-chips-container {
        margin-top: 6px;
        display: flex;

        .chip {
            height: 20px;
            margin-right: 6px;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white);
            border-radius: 10px;
            font-size: 14px;
            line-height: 16px;

            &.parent {
                background-color: var(--purple);
            }

            &.student {
                background-color: var(--orange);
            }

            &.teacher {
                background-color: var(--blue);
            }
        }
    }
}

.selected-person-container {
    width: 100%;
    padding: 16px;
    background-color: var(--white);
    box-shadow: var(--shadow);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selected-person-content {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .profile-image {
            flex-shrink: 0;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--super-light-gray);
            border-radius: 50%;
        }

        .info-container {
            .search-result-name {
                font-size: 16px;
                font-weight: 500;
                color: var(--dark-primary);
            }

            .search-result-email {
                margin-top: 4px;
                font-size: 14px;
                color: var(--medium-gray);
            }

            .search-result-id {
                margin-top: 2px;
                font-size: 12px;
                color: var(--medium-gray);
            }


        }
    }

    .person-type-chips-container {
        margin-top: 6px;
        display: flex;

        .chip {
            height: 20px;
            margin-right: 6px;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white);
            border-radius: 10px;
            font-size: 14px;
            line-height: 16px;

            &.parent {
                background-color: var(--purple);
            }

            &.student {
                background-color: var(--orange);
            }

            &.teacher {
                background-color: var(--blue);
            }
        }
    }
}