.summary-number {
  height: 100%;
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  .summary-number-value {
    width: 100%;
    text-align: center;
    font-size: 56px;
    font-weight: 500;
  }
}
