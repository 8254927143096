.upcoming-shipment-schedule {
  --top-padding: 32px;
  --title-height: 35px;
  --title-bottom-margin: 16px;
  --upper-height: 175px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--top-padding) 44px;
  background-color: var(--background);
  overflow-y: scroll;

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 28px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: var(--header-height);
    margin-bottom: var(--header-bottom-margin);
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;

    &.table-header {
      height: calc(var(--header-height) + 16px);
      padding-top: 48px;
    }
  }

  .upper {
    height: var(--upper-height);
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.additional {
          margin-top: -12px;
        }

        .info-title {
          width: 220px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);

          .seats-available-details {
            margin-left: 12px;
            font-size: 14px;
            color: var(--medium-gray);
          }
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .shipment-loading {
    height: calc(
      100% - var(--title-height) - var(--title-bottom-margin) - var(--upper-height) - var(--header-height) -
        var(--header-bottom-margin) - 50px
    );
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-items-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.product {
        display: flex;
        align-items: center;

        .product-picture {
          height: 56px;
          width: 56px;
          margin-right: 16px;
          border-radius: var(--card-border-radius);
          object-fit: cover;

          &.placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--medium-gray);
            background-color: var(--light-gray);

            &.female {
              color: var(--female-pink);
              background-color: var(--female-pink-semi-transparent);
            }

            &.male {
              color: var(--male-blue);
              background-color: var(--male-blue-semi-transparent);
            }
          }
        }

        .aux {
          color: var(--medium-gray);
          font-size: 14px;
        }
      }

      .quantity-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;

        > :not(:last-child) {
          margin-right: 12px;
        }

        .quantity-icon {
          font-size: 20px;
          color: var(--dark-primary);
          cursor: pointer;

          &:active {
            opacity: 0.8;
          }

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }
        }
      }
    }
  }
}
