html {
  --sidebar-width: 268px;

  --content-height: 100vh;
  --content-width: calc(100vw - var(--sidebar-width));
}

* {
  box-sizing: border-box;
}
