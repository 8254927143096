.activity-tab {
  height: 100%;
  min-height: 350px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--medium-gray);
  font-size: 24px;
}
