.playlist-details {
  --top-padding: 32px;
  --horizontal-padding: 44px;
  --title-height: 25px;
  --title-bottom-margin: 16px;
  --upper-height: 175px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--top-padding) var(--horizontal-padding) 0px;
  background-color: var(--background);
  overflow-y: scroll;

  &.playlist-details-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: var(--header-height);
    margin-bottom: var(--header-bottom-margin);
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .tabs-container {
    padding-bottom: var(--vertical-padding);

    .tab-bar {
      height: 52px;
      display: grid;
      grid-template-rows: 1fr;

      .tab {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--medium-gray);
        border-bottom: 3px solid var(--light-gray);
        transition: border-bottom 400ms;
        cursor: pointer;

        &.active {
          color: var(--gray);
          border-bottom: 3px solid var(--dark-primary);
        }
      }
    }

    .active-tab-section {
    }
  }
}
