.playlist-info-tab {
  height: 100%;
  min-height: 350px;
  width: 100%;
  display: flex;

  .info-col {
    flex: 1;

    .info-section-header {
      padding-top: 32px;
      margin-bottom: 12px;
      color: var(--medium-gray);
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .artwork {
      width: 65%;
      height: auto;
      box-shadow: var(--big-shadow);
    }

    .info-row {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .info-title {
        width: 156px;
        margin-right: 12px;
        font-weight: 500;
        text-align: right;
      }

      .info-value {
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--dark-primary);

        &.empty {
          color: var(--medium-gray);
        }
      }

      .info-action {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--orange);
        font-weight: 600;
        user-select: none;

        > span {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          &:active {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
