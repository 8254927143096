.shipping {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.shipping-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .header {
    height: 55px;
    margin-bottom: 12px;
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .upper {
    display: flex;

    .info-section {
      flex: 1;

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-title {
          width: 220px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);
        }
      }
    }

    .actions-section {
      flex: 1;

      .action-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          flex: 1;
          height: 20px;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          a {
            color: var(--orange);
          }

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .sort-search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort-shipments,
    .filter-shipments {
      display: flex;
      align-items: center;
      min-width: 400px;

      .sort-shipments-label,
      .filter-shipments-label {
        margin-right: 16px;
        font-weight: 500;
      }
    }

    .filter-shipments {
      min-width: 300px;
    }

    .search-shipments {
      min-width: 360px;
    }
  }

  .order-items-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.teacher {
        display: flex;
        align-items: center;

        .profile-picture {
          height: 56px;
          width: 56px;
          margin-right: 16px;
          border-radius: 50%;
          object-fit: cover;

          &.placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--medium-gray);
            background-color: var(--light-gray);
            box-shadow: var(--shadow);

            &.female {
              color: var(--female-pink);
              background-color: var(--female-pink-semi-transparent);
            }

            &.male {
              color: var(--male-blue);
              background-color: var(--male-blue-semi-transparent);
            }
          }
        }

        .aux {
          color: var(--medium-gray);
          font-size: 14px;
        }
      }
    }
  }
}
