.lpm-checkbox {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  + .lpm-checkbox {
    margin-top: 16px;
  }

  .lpm-checkbox-box {
    height: 24px;
    width: 24px;
    margin-right: 20px;
    border: 2px solid var(--dark-primary);
    box-sizing: border-box;

    &.selected {
      background-color: var(--dark-primary);
    }
  }
}
