.albums-tab {
  width: 100%;

  .albums-table {
    margin-top: 32px;
    margin-bottom: var(--vertical-padding);

    tbody tr td {
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
    }

    .cell-contents {
      &.artwork {
        height: 80px;
        position: relative;

        .album-artwork {
          height: 80px;
          width: 80px;
          position: absolute;
          box-shadow: var(--shadow);
        }
      }

      > :not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  .no-albums-container {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .no-albums-message {
      margin-bottom: 32px;
      color: var(--medium-gray);
      font-size: 28px;
      font-weight: 600;
    }
  }
}
