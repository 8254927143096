.analytics-enrollments-tab {
  width: 100%;

  .wip {
    margin-top: 36px;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > span {
      margin-left: 20px;
      color: var(--yellow);
      font-size: 32px;
      font-weight: 500;
    }
  }

  .collections-container {
    margin-top: 24px;

    .loading-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
