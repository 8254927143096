.delete-relationship-modal {
    height: max-content;
    width: 650px;
  
    position: relative;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    overflow-y: scroll;
  
    .content {
      width: 100%;
      padding: 0px 30px;
      display: flex;
      flex-direction: column;
  
      .title {
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 500;
      }
  
      .section {
        margin-bottom: 36px;
  
        .section-header {
          margin-bottom: 8px;
          color: var(--medium-gray);
          font-weight: 500;
          text-transform: uppercase;
        }
      }
  
      .warning-section {
        .warning-text {
          color: var(--red);
        }
      }
    }
  
    .bottom-buttons {
      flex-shrink: 0;
      height: 60px;
      width: 100%;
      padding: 0px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
  
      .left-buttons {
        display: flex;
  
        > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  
    .relationship-info {
      .relationship-users {
        display: flex;
        flex-direction: column;
        gap: 8px;
  
        .label {
          font-weight: 500;
          color: var(--medium-gray);
        }
      }
    }
  
    .warning-section {
      .warning-text {
        .description {
          font-weight: 500;
          color: var(--red);
          margin-bottom: 8px;
        }
  
        .additional-warning {
          color: var(--medium-gray);
          font-size: 14px;
        }
      }
    }
  }