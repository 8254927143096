.track-details {
  --top-padding: 32px;
  --horizontal-padding: 44px;
  --title-height: 25px;
  --title-bottom-margin: 16px;
  --upper-height: 175px;
  --header-height: 55px;
  --header-bottom-margin: 12px;

  height: var(--content-height);
  width: var(--content-width);
  padding: var(--top-padding) var(--horizontal-padding) 0px;
  background-color: var(--background);
  overflow-y: scroll;

  &.track-details-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    height: var(--title-height);
    margin-bottom: var(--title-bottom-margin);
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      display: flex;
      align-items: center;

      .arrow-icon {
        margin: 0px 12px;
      }
    }
  }

  .loading-container {
    height: calc(100% - var(--title-height) - var(--title-bottom-margin));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: var(--header-height);
    margin-bottom: var(--header-bottom-margin);
    padding-top: 32px;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .track-details-body {
    width: 100%;
    display: flex;

    .info-col {
      flex: 1;

      .info-section-header {
        padding-top: 32px;
        margin-bottom: 12px;
        color: var(--medium-gray);
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .audio-player-wrapper {
        width: 75%;
        padding: 12px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        box-shadow: var(--shadow);

        audio {
          width: 100%;
        }
      }

      .artwork {
        width: 75%;
        height: auto;
        box-shadow: var(--big-shadow);
      }

      .info-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.multi {
          align-items: flex-start;
        }

        .info-title {
          width: 156px;
          margin-right: 12px;
          font-weight: 500;
          text-align: right;
        }

        .info-value {
          flex: 1;
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--dark-primary);

          &.learning-purposes {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &.empty {
              color: var(--medium-gray);
            }

            .learning-purpose {
              margin-top: 8px;
            }
          }
        }

        .info-action {
          flex: 1;
          margin-left: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: var(--orange);
          font-weight: 600;
          user-select: none;

          > span {
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
